import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { _api } from '../../services';

const SET_PRIMARY_MEMBERSHIP_MUTATION_KEY = 'v1SetPrimaryMembership';

export function useSetPrimaryMemberships() {
  const history = useHistory();

  const { isLoading, error, isError, mutate: onSetPrimaryMembership } = useMutation({
    mutationKey: [SET_PRIMARY_MEMBERSHIP_MUTATION_KEY],
    mutationFn: (orgId: string) => _api().httpPutRequest(`/v1/memberships/${orgId}/set_as_primary`),
    onSuccess: () => history.go(0),
    retry: false
  });

  return { isLoading, error, isError, onSetPrimaryMembership };
}
