import React, { useCallback } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Form } from 'react-final-form';
import get from 'lodash/fp/get';
import first from 'lodash/fp/first';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { FOUR_DIGIT_PIN } from '@kwara/components/src/Form';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';
import createValidator from '@kwara/lib/src/validator';

import { GuarantorshipRequests } from '../../models/GuarantorshipRequest';
import { Navigation } from '../../components/Navigation';
import { PINInput } from './components/PINInput';
import { StatusApprovalModal } from '../../components/StatusApprovalModal';
import { useStore } from '../../models/Member';
import image from './assets/img.svg';

type GuarantorshipRequestType = {
  id: string;
  type: 'guarantorship_requests';
  attributes: {
    created_at: string;
    guaranteed_amount: number;
    requesting_user_name: string;
    state: string;
    loan_details: {
      duration: string;
      duration_unit: string;
      id: string;
      instalment_amount: string;
      name: string;
      principal: number;
    };
  };
};

export const AcceptGuarantorshipRequest = ({ auth }) => {
  const store = useStore();

  const history = useHistory<{ loan: GuarantorshipRequestType }>();
  const { phoneNumber } = store.member;

  const [errors, setErrors] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [navigateToLanding, setNavigateToLanding] = React.useState(false);

  const { loan } = history.location.state;
  const { attributes, id: guarantorshipRequestId } = loan;
  const { guaranteed_amount, requesting_user_name } = attributes;

  const logOut = useCallback(async () => {
    await auth.logOutConnect();
    setNavigateToLanding(true);
    window.location.reload();
  }, [auth]);

  const aceptGuarantorship = React.useCallback(async () => {
    try {
      await GuarantorshipRequests.approveGuarantorship(guarantorshipRequestId);
      setShowModal(true);
    } catch ({ errors }) {
      const firstError = first(errors);
      const title = get('code', firstError);
      setShowModal(false);
      setErrors(title);
    }
  }, [guarantorshipRequestId]);

  const onVerify = React.useCallback(
    async data => {
      const { pin } = data;
      try {
        await auth.connectWithFourDigitLogIn({
          phone: phoneNumber,
          pin
        });
        aceptGuarantorship();
      } catch ({ errors }) {
        const firstError = first(errors);
        const title = get('code', firstError);
        setShowModal(false);
        setErrors([{ code: title }]);
        if (title === 'PIN_LOCKED') {
          logOut();
        }
      }
    },
    [aceptGuarantorship, logOut, auth, phoneNumber]
  );

  return (
    <>
      {navigateToLanding ? <Redirect to="/" /> : null}
      <StatusApprovalModal
        show={showModal}
        type="info"
        titleId={'GuarantorshipRequest.Approve.title'}
        subtitleId={'GuarantorshipRequest.Approve.subtitle'}
        subtitleValues={{ name: requesting_user_name, guaranteedAmount: String(guaranteed_amount) }}
        buttonId={'GuarantorshipRequest.Approve.action'}
        onConfirm={() => history.push('/')}
      />
      <div className={'bg-white flex flex-column h-100 w-100'}>
        <div className={'ph3'}>
          <div className="flex">
            <Navigation onClick={() => history.push('/')} />
          </div>
          <div className="pt3 ph4 tc">
            <h2 className="mobile-text-large kw-weight-bold">
              <Text id="GuarantorshipRequest.Accept.title" />
            </h2>
          </div>
          <div className="flex justify-center">
            <img src={String(image)} alt="logo" />
          </div>
          <Form
            validate={createValidator(validateConfig)}
            onSubmit={data => onVerify(data)}
            render={({ handleSubmit, invalid, submitting }) => {
              return (
                <form className={'flex flex-column h-75 mobile-neutral-600'} onSubmit={handleSubmit}>
                  <div className="pv4">
                    {errors && <ModelErrorBanner className="mt4 mb4" errors={errors} state="error" />}
                    <PINInput />
                  </div>
                  <Button
                    isSubmit
                    aria-label="Create"
                    type="primary"
                    size="medium"
                    className="fr"
                    disabled={submitting || invalid}
                    glyphRightId={submitting ? Button.Glyphs.Spinner : null}
                  >
                    <Text id="GuarantorshipRequest.Accept.verify" />
                  </Button>
                </form>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

const validateConfig = {
  pin: {
    isRequired: () => true,
    minlength: FOUR_DIGIT_PIN
  }
};
