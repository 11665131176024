import { HttpService } from '@kwara/lib/src/services';

import apiConfig from '../config';
import auth from '../lib/auth';

/**
 * @_api used for accessing private endpoints
 *
 * @note this is a function to ensure that at the time of invocation,
 * the latest state of `auth.isDuara()` is used and not staled.
 */

function _api() {
  return new HttpService({
    baseURL: auth.isDuara() ? `${apiConfig.API_ROOT}/duara` : apiConfig.API_ROOT,
    authRequest: true,
    getToken: auth.getRawToken
  });
}

/**
 * @$api used for accessing public endpoints
 */
const $api = new HttpService({
  baseURL: apiConfig.API_ROOT,
  authRequest: false
});

export { _api, $api };
