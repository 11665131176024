import { attr } from 'spraypaint';

import Base, { BaseModel } from '@kwara/models/src/models/Base';

import { _api } from '../services';

export const GuaranteedLoans = Base.extend({
  static: {
    jsonapiType: 'guaranteed_loans',
    async fetchGuaranteedLoans() {
      const response = await _api().httpGetRequest<GuaranteedLoansType>('/v1/guarantorship/guaranteed_loans');
      return response.data;
    }
  },
  attrs: {
    loanOwner: attr(),
    loanName: attr(),
    loanPrincipal: attr(),
    loanDuration: attr(),
    loanDurationUnit: attr(),
    loanState: attr(),
    guaranteedAmount: attr(),
    guarantorshipRequestDate: attr()
  }
});

export interface GuaranteedLoansType extends BaseModel<GuaranteedLoansType> {
  loanOwner: string;
  loanName: string;
  loanPrincipal: number;
  loanDuration: number;
  loanDurationUnit: string;
  loanState: string;
  guaranteedAmount: string;
  guarantorshipRequestDate: string;
}
