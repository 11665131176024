import { attr, belongsTo } from 'spraypaint';

import Base, { BaseModel } from '@kwara/models/src/models/Base';
import Loan, { LoanType } from '@kwara/models/src/models/Loan';

import { _api } from '../services';

export const GuarantorshipRequests = Base.extend({
  static: {
    jsonapiType: 'guarantorship_requests',
    async fetchGuarantorshipRequests() {
      const response = await _api().httpGetRequest<GuarantorshipRequestsType>('/v1/guarantorship/received_requests');
      return response.data;
    },
    async approveGuarantorship(id: string) {
      const url = `/v1/guarantorship/received_requests/${id}/approve`;
      const response = await _api().httpPostRequest(url, { data: { id: Number(id), type: 'guarantorship_requests' } });
      return response.data;
    },
    async declineGuarantorship(id: string) {
      const url = `/v1/guarantorship/received_requests/${id}/decline`;
      const response = await _api().httpPostRequest(url, { data: { id: Number(id), type: 'guarantorship_requests' } });
      return response.data;
    }
  },
  attrs: {
    requestingUserName: attr(),
    guaranteedAmount: attr(),
    loanDetails: belongsTo({ type: Loan }),
    state: attr()
  }
});

export interface GuarantorshipRequestsType extends BaseModel<GuarantorshipRequestsType> {
  requestingUserName: string;
  guaranteedAmount: number;
  state: string;
  loanDetails(): LoanType;
}
