// @flow
import { useModelsRequest } from '@kwara/models/src/models/request';
import { useQuery } from 'react-query';
import { MemberEligibility, Organisation } from '@kwara/models/src';

import { GuarantorshipRequests } from './GuarantorshipRequest';
import { GuaranteedLoans } from './GuaranteedLoans';

export const defaultUseMemberEligibilityIncludes = ['product'];
export const useMemberEligibility = (includes: IncludesT = defaultUseMemberEligibilityIncludes) => {
  return useModelsRequest<MemberEligibilityT>({ entity: MemberEligibility, includes });
};

export const useGuarantorshipRequest = (token: string) =>
  useQuery(['guarantorshipRequest', token], GuarantorshipRequests.fetchGuarantorshipRequests);

export const useGuaranteedLoans = (token: string) =>
  useQuery(['guaranteedLoans', token], GuaranteedLoans.fetchGuaranteedLoans);

async function fetchOrganisations() {
  try {
    const res = await Organisation.all();
    return res.data;
  } catch {
    return [];
  }
}

export const useOrganisations = (token: string) =>
  useQuery(['organisations', token], fetchOrganisations, {
    refetchOnWindowFocus: false
  });
