import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import 'babel-polyfill';
import 'raf/polyfill'; // React and old IE support

import '@kwara/components/src/foundations';

import App from './App';
import auth from './lib/auth';

import { appName } from '@kwara/lib/src/utils';
import { configureModels } from '@kwara/models/src';
import { configureStores } from '@kwara/components/src/configureStores';
import { Provider as IntlProvider, prepareTranslations } from '@kwara/components/src/Intl';
import { NotificationProvider } from '@kwara/components/src/Notification/NotificationProvider';

import config from './config';
import en from './translations/en.json';

import { TOKEN_KEY } from './lib/auth';
import { store } from './models/Store';
import { ConnectAuthContext } from './hooks';
import { MemberProvider } from './models/Member';

configureModels({ apiBaseUrl: config.API_BASE_URL, jwtStorageKey: TOKEN_KEY });
configureStores(store);

appName.current = config.appName;
const translations = prepareTranslations(en);
const memberClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider messages={translations}>
      <QueryClientProvider client={memberClient}>
        <ConnectAuthContext>
          <NotificationProvider>
            <MemberProvider auth={auth}>
              <App />
            </MemberProvider>
          </NotificationProvider>
        </ConnectAuthContext>
      </QueryClientProvider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
