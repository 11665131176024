import React, { useRef } from 'react';

import { createContext } from '@kwara/lib/src/utils/createContext';

import auth from '../lib/auth';

type AuthContextType = typeof auth;

const [AuthContextProvider, useAuth] = createContext<AuthContextType>('AuthContext');

type Props = {
  children: React.ReactNode;
};

function ConnectAuthContext(props: Props) {
  const authRef = useRef(auth);

  return <AuthContextProvider value={authRef.current}>{props.children}</AuthContextProvider>;
}

export { useAuth, ConnectAuthContext };
