// @flow
import * as React from 'react';
import get from 'lodash/fp/get';

import { hasErrors } from '@kwara/models/src/models/request';
import { useSegmentIdentify } from '@kwara/components/src/Segment';
import { configureApiNamespace } from '@kwara/models/src/models/Base';
import { useConnectProfile, useMember } from '@kwara/models/src/models/request/hooks';

import { Loader } from './Loader';
import { store, Store } from './Store';
import { GoogleTagManager } from '../lib/GoogleTagManager';
import { NewUserStoreProvider, useNewUserStoreContextValues } from './NewUserStore';

const MemberContext = React.createContext<Store | {}>({});
export const useStore = () => React.useContext(MemberContext);

export const MemberProvider = ({ auth, children }) => {
  const values = useNewUserStoreContextValues();

  if (!auth.isLoggedIn()) {
    return <NewUserStoreProvider value={values}>{children}</NewUserStoreProvider>;
  }

  return (
    <NewUserStoreProvider value={values}>
      <AuthorizedMemberProvider auth={auth} children={children} />
    </NewUserStoreProvider>
  );
};

export const withMember = Component => props => (
  <MemberContext.Consumer>{store => <Component {...store} {...props} />}</MemberContext.Consumer>
);

const memberIncludes = ['branch.address', 'attachments'];
const AuthorizedMemberProvider = ({ auth, children }) => {
  const { isLoading, data, error } = useMember(store.memberId, memberIncludes);
  const profileR = useConnectProfile();
  useSegmentIdentify(auth);

  configureApiNamespace(auth.isDuara() ? '/duara' : '');

  if (isLoading) {
    return <Loader />;
  }

  if (hasErrors(error)) {
    auth.logOutConnect();
    window.location.replace('/');
    return null;
  }

  store.updateMember(data);

  if (!profileR.isLoading) {
    store.setProfile(get('data', profileR));
  }

  GoogleTagManager.triggerUserLogin();

  return <MemberContext.Provider value={store}>{children}</MemberContext.Provider>;
};
