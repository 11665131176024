import { attr } from 'spraypaint';

import {
  AirtimePurchaseType,
  PurchaseAirtimeData,
  PurchaseAirtimeArg,
  AirtimePurchaseFunction
} from 'AirtimePurchaseTypes';

import Base from '@kwara/models/src/models/Base';

import { _api } from '../../services';

enum BUY_AIRTIME_FOR {
  MY_SELF = 'MY_SELF',
  ANOTHER = 'ANOTHER'
}

/**
 * @STATUS the purchase statuses
 */
enum STATUS {
  PENDING = 'pending',
  PROCESSING = 'processing',
  SUCCEEDED = 'succeeded',
  FAILED_C2B_PAYMENT = 'failed_c2b_payment',
  FAILED_AIRTIME_PURCHASE = 'failed_airtime_purchase',
  GENERIC_FAILURE = 'generic_failure',
  TIMEOUT = 'timeout'
}

const STATUS_POLLING_INTERVAL = 1000;

/**
 * @RAW_MIN_CASHBACK the minimum possible cashback which mean,
 * if a cashback of 0.99 or less is not payable.
 * used with @getCashbackAmount(1).raw
 */
const RAW_MIN_CASHBACK = 1.05;

/**
 * @FORMATTED_MIN_CASHBACK used with @getCashbackAmount(1).formatted
 */
const FORMATTED_MIN_CASHBACK = 1;

/**
 * @MIN_CASHBACKABLE_DEPOSIT the minimum deposit worth getting
 * a cashback. Meaning if you deposit 34.99 and less, you will get no cashback
 */
const MIN_CASHBACKABLE_DEPOSIT = 35;

/**
 * @STK_TIMEOUT_COUNTER the standard STK max-out counter
 * for an STK verification to be done by the airtime buyer
 */
const STK_TIMEOUT_COUNTER = 60;

/**
 * @REFUND_PERCENTAGE is the percentage an airtime buyer
 * gets back from the purchase amount
 */
const REFUND_PERCENTAGE = 0.03; //equivalent to 3%

/**
 * @MIN_PURCHASE_AMOUNT the minimum purchase
 * is 10 in any currency e.g 10 KES, 10 EUR
 */
const MIN_PURCHASE_AMOUNT = 10;

/**
 * @MAX_PURCHASE_AMOUNT the maximum purchase is 150,000
 * in any currency e.g 150,000 KES, 150,000 EUR
 */
const MAX_PURCHASE_AMOUNT = 150000;

/**
 * @getCashbackAmount
 *
 * raw: means the cashback is not truncated and we use it for mathematical operation
 * formatted: eans the cashback is truncated and its what we render to the user
 */
function getCashbackAmount(airtimeAmount: number) {
  const raw = REFUND_PERCENTAGE * airtimeAmount;

  return {
    raw,
    formatted: Math.trunc(raw)
  };
}

const AirtimePurchase = Base.extend({
  static: {
    jsonapiType: 'airtime_purchases',
    async purchaseAirtime({ airtimePhoneNumber, paymentPhoneNumber, amount }: PurchaseAirtimeArg) {
      const response = await _api().httpPostRequest<PurchaseAirtimeData, AirtimePurchaseType>(
        '/v1/payments/mobile/airtime/purchases',
        {
          data: {
            attributes: {
              amount: Number(amount),
              airtime_phone_number: airtimePhoneNumber,
              payment_phone_number: paymentPhoneNumber
            }
          }
        }
      );

      return response;
    }
  },
  attrs: {
    phoneNumber: attr(),
    purchaseAmount: attr(),
    purchaseStatus: attr()
  },
  methods: {
    async getPurchasedAirtime(id: string) {
      const response = await _api().httpGetRequest<AirtimePurchaseType>(`/v1/payments/mobile/airtime/purchases/${id}`);
      return response;
    }
  }
}) as AirtimePurchaseFunction;

export {
  AirtimePurchase,
  getCashbackAmount,
  MIN_PURCHASE_AMOUNT,
  MAX_PURCHASE_AMOUNT,
  STK_TIMEOUT_COUNTER,
  RAW_MIN_CASHBACK,
  FORMATTED_MIN_CASHBACK,
  STATUS_POLLING_INTERVAL,
  MIN_CASHBACKABLE_DEPOSIT,
  BUY_AIRTIME_FOR,
  STATUS
};
