import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { steps } from './config';
import { useSubmit } from './useSubmit';
import { Store } from '../../models/Store';
import { useStore } from '../../models/Member';
import Wizard, { getSubstepFromMatchParams } from '@kwara/components/src/Wizard/deprecated/Wizard';

type ParamsType = {
  organisationPermalink: string;
  step: string;
};

export function ActivateMembership() {
  const history = useHistory();
  const params = useParams<ParamsType>();
  const store: Store = useStore();
  const submit = useSubmit();

  return (
    <Wizard
      steps={steps}
      startId="identity"
      cancelReturnsTo="/overview/loans"
      analyticsId="ActivateMembership"
      titleId="ActivateMembership.title"
      baseUrl={`/memberships/activation/${params.organisationPermalink}`}
      history={history}
      initialData={{
        phone: store.profile?.phone,
        organisation_permalink: params.organisationPermalink
      }}
      onSubmit={submit}
      currentStep={params.step}
      currentSubStep={getSubstepFromMatchParams(params)}
    />
  );
}
