import { useEffect, useRef, useCallback } from 'react';

import { DetectUserActivity } from '@kwara/components/src';
import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';

import { ROUTES } from './routes';
import { useAuth } from '../hooks';
import { GoogleTagManager } from '../lib/GoogleTagManager';
import { instantPrompt } from '../components/InstantPrompt/models/InstantPrompt';

export function useRouter() {
  const { current: activityChecker } = useRef(new DetectUserActivity());
  const [contactModalOpen, { toggle: toggleContactModal }] = useBoolean();
  const auth = useAuth();

  useEffect(() => {
    GoogleTagManager.initialize();
    return () => activityChecker.destroy();
  }, [activityChecker]);

  const logOut = useCallback(() => {
    if (auth) {
      auth.logOutConnect();
      instantPrompt.unmount();
      window.location.replace(ROUTES.home);
    }
  }, [auth]);

  const matchSectionFromPathname = useCallback((pathname: string = '', sections: Record<string, string>) => {
    const endOfPathMatcher = /\/(\w*)$/;
    const match = pathname.match(endOfPathMatcher);
    const section = match ? match[1] : null;

    return sections[section] ? section : Object.keys(sections)[0];
  }, []);

  return { activityChecker, contactModalOpen, toggleContactModal, logOut, matchSectionFromPathname };
}
